import React from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { path } from "constants/common";
import { Layout, SEO } from "components";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="404" />
      <article className="not-found container">
        <h1>404</h1>
        <h2>{t`config.notFound.message`}</h2>
        <Link to={path.home} className="not-found__btn">
          {t`config.notFound.back`}
        </Link>
      </article>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
